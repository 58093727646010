import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Watch List" link="https://watch-indol.vercel.app" bg="linear-gradient(to right, #86C166 0%, #005cAF 100%)" mdxType="ProjectCard">
  This is a fetch image admin project with Vue 3 + nest.js + mongoDB.
    </ProjectCard>
    <ProjectCard title="Bingo-BOX-React" link="https://github.com/Void-YY/bingo-box" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  This is a Bingo game project with Next.js.
    </ProjectCard>
    <ProjectCard title="Bingo-BOX-Vue3" link="https://socket-bingo.vercel.app/control-panel" bg="linear-gradient(to right, green 0%, yellow 100%)" mdxType="ProjectCard">
  This is a Bingo game project with Vue 3.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      