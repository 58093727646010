import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Hi, I'm Void-YY`}</h1>
    <ul>
      <li parentName="ul">{`🔭 Currently working on Full-stack project.`}</li>
      <li parentName="ul">{`🌱 Learning TypeScript.`}</li>
      <li parentName="ul">{`🤔 Looking for help with React Study.`}</li>
      <li parentName="ul">{`💬 Ask me about JavaScript maybe.`}</li>
      <li parentName="ul">{`📫 How to reach me: `}<a parentName="li" {...{
          "href": "mailto:voidwhyy@gmail.com"
        }}>{`voidwhyy@gmail.com`}</a></li>
      <li parentName="ul">{`📝 My Blog: `}<a parentName="li" {...{
          "href": "https://blog.void-yy.com"
        }}>{`https://blog.void-yy.com`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      